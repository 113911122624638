import logo from "./logo.svg"

const clientData = {
    client_entity: 48,
    attorney_firm: 'William C. Cunningham Attorney at Law',
    attorney_name: 'William Cunningham',
    attorney_number: '662-329-2455',
    attorney_email: 'bill@329bill.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_william_cunningham+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#504291',
    siteLink: 'http://www.329bill.com/',
    logo
}

export default clientData